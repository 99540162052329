import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
    <header className="App-header">
      <h1>CareerKit.io</h1>
      <h2>A website where you can get resources relevant to your career and hobbies...</h2>
      <h3>Coming Soon...</h3>
      <h5>Contact <i>FrankGRiviera@outlook.com</i> for more details...</h5>
      <p> CareerKit 2022</p>
    </header>
  </div>
  );
}

export default App;
